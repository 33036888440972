.detail{
	background: #F9F9F9;
	font-size: 14px;
	line-height: 20px;
	height: 100%;
	/deep/ .group-share-box{
		background: none !important;
		top: 125px !important;
		right: -125px;
		left: auto;
		.group-share{
			width: 298px;
		}
	}
}

.detail-header{
    position: relative;
    .banner{
        width: 100%;
        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/bg_banner.png) center/100% 100% no-repeat;
        .ban-img{
            width: 100%;
            height: 100%;
        }
    }
    .banner-01{
        height: auto;
    }
    .navigation{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px 15px 0 15px;
        .lt{
            display: flex;
            .record{
                background: rgba(0, 0, 0, .5);
                border-radius: 17px;
                line-height: 30px;
                height: 30px;
                width: 155px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 11px;
                color: #ffffff;
                .record-swiper{
                    width: 100%;
                    height: 100%;
                    .van-swipe-item{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 4px;
                        .img{
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .back{
                width: 30px;
                height: 30px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_back.png) center/100% 100% no-repeat;
                margin-right: 13px;
            }
        }
        .rt{
            display: flex;
            .zhuan{
                width: 54px;
                height: 30px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_zhuan.png) center/100% 100% no-repeat;
            }
            .mine{
                width: 30px;
                height: 30px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_mine.png) center/100% 100% no-repeat;
                margin-left: 10px;
            }
            .share{
                width: 30px;
                height: 30px;
                background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_share.png) center/100% 100% no-repeat;
                margin-left: 10px;
            }
        }
    }
    .code-box{
        width: 295px;
        height: 420px;
        background: url(https://file.huatu.com/static/miniprogram/shop/share_bg_tuiguang.png) center/100% 100% no-repeat;
        .ewm{
            width: 90px;
            height: 90px;
        }
    }
    .save-btn{
        width: 295px;
        line-height: 42px;
        font-size: 15px;
        text-align: center;
        margin: 20px auto;
        background: linear-gradient(135deg, #F65723 0%, #F5441B 100%);
        border-radius: 20px;
        color: #fff;
    }
    .myCanvas-box{
        img{
            position: fixed;
            top: -1000px;
            left: -1000px;
        }
    }
    /deep/.van-popup{
        background: none;
    }
}

.detail-code{
    margin-bottom: 10px;
    height: 67px;
    background: #ffffff;
    padding: 12px 15px;
    .tit{
        color: #FC6C21;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-copy{
            display: block;
            width: 18px;
            height: 18px;
            margin-left: 5px;
            background: url(../../assets/icons/icon-copy.png) center/100% 100% no-repeat;
        }
    }
    .txt{
        font-size: 13px;
        color: #A8A8A8;
        line-height: 18px;
    }
}

.detail-footer{
    .footer{
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffff;
        .footer-count{
            background: rgba(255, 100, 31, 0.85);
            color: #ffffff;
            font-size: 13px;
            text-align: center;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            /deep/ .van-count-down{
                color: #ffffff;
            }
            .lt{
                display: flex;
                justify-content: flex-start;
            }
            .rt{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .head-img{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .img{
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                    }
                }
                .icon-right{
                    display: block;
                    width: 16px;
                    height: 16px;
                    background: url(../../assets/image/detail/icon-right.png) center/100%,100% no-repeat;
                    margin-left: 8px;
                }
            }
        }
        .footer-count-01{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            padding: 0 15px;
        }
        .footer-bt{
            height: 56px;
            // padding: 0 15px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .lt{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .item{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 0 13px;
                    font-size: 11px;
                    line-height: 16px;
                    .icon-shop{
                        display: block;
                        width: 20px;
                        height: 20px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_shop.png) center/100% 100% no-repeat;
                    }
                    .icon-kefu{
                        display: block;
                        width: 20px;
                        height: 20px;
                        background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_kefu.png) center/100% 100% no-repeat;
                    }
                }
            }
            .rt{
                display: flex;
                justify-content: center;
                align-items: center;
                .btn{
                    width: 236px;
                    line-height: 40px;
                    background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
                    border-radius: 20px;
                    color: #ffffff;
                    font-weight: 500;
                    text-align: center;
                }
                .btn-01{
                    background: #C4C4C4;
                }
                .btn-small{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 114px;
                    height: 40px;
                    box-sizing: border-box;
                    border-radius: 22px;
                    .tit{
                        font-weight: bold;
                    }
                    .txt{
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .btn-small-no{
                    border: 1px solid #FC6C21;
                    color: #FC6C21;
                }
                .btn-small-on{
                    background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
                    color: #ffffff;
                    margin-left: 8px;
                }
                .btn-small-gray{
                    background: #C4C4C4;
                    color: #ffffff;
                    margin-left: 8px;
                }
            }
        }
    }
    .station{
        height: 100px;
    }
}

.detail-tabs{
    .intro{
        background: #ffffff;
        padding: 15px;
    }
    .ms-intro{
        img{
            width: 100%;
        }
    }
    .teacher{
        .tit{
            font-size: 17px;
            color: #333333;
            line-height: 24px;
            padding: 14px 24px;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon{
                display: block;
                width: 3px;
                height: 15px;
                background: #FC6C21;
                border-radius: 2px;
                margin-right: 6px;
            }
        }
        .item{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            background: #fff;
            padding: 15px 0;
            .lt{
                .img-head{
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                }
                padding: 0 15px;
            }
            .rt{
                .name{
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 22px;
                }
                .subject{
                    font-weight: 500;
                    color: #FC6C21;
                    line-height: 17px;
                    padding-top: 10px;
                }
                .summary{
                    font-size: 14px;
                    color: #999999;
                    line-height: 22px;
                    padding-top: 10px;
                }
            }
        }
    }
    /deep/.van-tabs__line{
        z-index: 0;
    }
}

.detail-data{
    border-radius: 4px;
    padding: 0 15px 12px 15px;
    background: #ffffff;
    margin-bottom: 10px;
    .data-item{
        border-bottom: 1px solid #F8F8F8;
        padding: 15px 0;
        .tit{
            font-size: 15px;
            font-weight: 500;
            color: #333333;
            line-height: 21px;
            padding-bottom: 15px;
        }
        .bt{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            .icon-pdf{
                display: block;
                width: 16px;
                height: 16px;
                background: url(../../assets/icons/icon-pdf.png) center/100% 100% no-repeat;
                margin-top: 2px;
            }
            .txt{
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                padding-left: 10px;
                width: 289px;
                white-space:normal; 
                word-break:break-all;
            }
        }
    }
    .data-item:last-child{
        border: none;
    }
    .more{
		text-align: center;
		font-size: 11px;
		color: #FC6C21;
		line-height: 16px;
		padding: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		.icon-more{
			display: block;
			width: 10px;
			height: 10px;
			margin-left: 2px;
			background: url(../../assets/icons/icon-more.png) center/100% 100% no-repeat;
		}
	}
}

.detail-comment{
    border-radius: 4px;
    padding: 0 15px 12px 15px;
    background: #ffffff;
    margin-bottom: 10px;
    .item{
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #F8F8F8;
        .lt{
            width: 30px;
            height: 30px;
            .img-head{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .rt{
            flex: 1;
            padding-left: 10px;
            .tp{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .name{
                    font-size: 16px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 22px;
                }
                .stars{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .icon-stars-on{
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: url(../../assets/icons/icon-stars-on.png) center/100% 100% no-repeat;
                        margin-left: 2px;
                    }
                    .icon-stars{
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: url(../../assets/icons/icon-stars.png) center/100% 100% no-repeat;
                        margin-left: 2px;
                    }
                }
            }
            .content{
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                padding-top: 15px;
            }
            .time{
                font-size: 12px;
                color: #999999;
                line-height: 17px;
                padding-top: 12px;
            }
        }
    }
    .item:last-child{
        border: none;
    }
}

.page-lesson{
	padding: 15px;
	background: #FFFFFF;
	box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
	border-radius: 4px;
	.tit-box{
		display: flex;
		justify-content: space-between;
		align-items: center;
        position: relative;
		.top-green{
			width: 16px;
			height: 16px;
			background: url(../../assets/icons/top-green.png) center/100% 100% no-repeat;
			transform: rotate(180deg);/*旋转180度 */
		}
		.top-green-01{
				transform: rotate(0deg);/*旋转180度 */
		}
		.tit{
			font-size: 15px;
			line-height: 21px;
			font-weight: 500;
			padding: 10px 40px 10px 0;
		}
		.tit-01{
			font-weight: 400;
            padding-left: 30px;
		}
        .icon-radio{
			display: block;
			width: 40px;
			height: 20px;
			background: url(../../assets/icons/icon-radio.png) center/100% 100% no-repeat;
			position: absolute;
			right: 20px;
			top: 10px;
		}
	}
	.tit{
		font-size: 15px;
		line-height: 21px;
		font-weight: 500;
		padding: 10px 0;
	}
	.lesson-item{
		background: rgba(252, 108, 33, 0.03);
		padding-top: 15px;
		position: relative;
		.icon-radio{
			display: block;
			width: 40px;
			height: 20px;
			background: url(../../assets/icons/icon-radio.png) center/100% 100% no-repeat;
			position: absolute;
			right: 8px;
			top: 2px;
		}
	}
	.lesson-item-01{
		padding-top: 0;
		background: #FFFFFF;
		border-radius: 4px;
	}
	.li{
		border-left: 2px solid #FFE8DC;
		padding: 0 0 24px 16px;
		margin: 0 0 0 19px;
		position: relative;
		.name{
			font-size: 15px;
			font-weight: 500;
			color: #333333;
			line-height: 21px;
		}
		.txt{
			font-size: 13px;
			color: #999999;
			line-height: 18px;
			padding: 8px 0 0 0;
		}
		.icon-dian{
			position: absolute;
			top: 7px;
			left: -6px;
			width: 10px;
			height: 10px;
			background: url(https://file.huatu.com/static/miniprogram/shop/h5/detail/icon_dian.png) center/100% 100% no-repeat;
		}
	}
	.more{
		text-align: center;
		font-size: 11px;
		color: #FC6C21;
		line-height: 16px;
		padding: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		.icon-more{
			display: block;
			width: 10px;
			height: 10px;
			margin-left: 2px;
			background: url(../../assets/icons/icon-more.png) center/100% 100% no-repeat;
		}
	}
}

.detail-features{
    margin-bottom: 10px;
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F1F1F1;
        padding: 10px 15px;
        background: #ffffff;
        .lt{
            font-size: 13px;
            color: #999999;
            line-height: 18px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .txt{
                color: #333333;
                padding-left: 15px;
            }
        }
        .rt{
            .icon-right{
                display: block;
                width: 14px;
                height: 14px;
                background: url(../../assets/icons/icon-right.png) center/100%,100% no-repeat;
            }
        }
    }
    .item:last-child{
        border: none;
    }
    .pt-box{
        padding: 20px 20px 0 20px;
        position: relative;
        .tit{
            color: #666666;
            line-height: 20px;
            padding-bottom: 20px;
        }
        .txt{
            color: #333333;
            line-height: 20px;
            padding-bottom: 15px;
            // display: flex;
            // align-items: center;
            // justify-content: flex-start;
            // flex-wrap: wrap;
        }
        /deep/.van-count-down{
            display: inline-block;
        }
        .btn{
            width: 90%;
            line-height: 40px;
            background: linear-gradient(90deg, #FF9D5B 0%, #FC6C21 100%);
            border-radius: 20px;
            font-size: 15px;
            color: #ffffff;
            font-weight: bold;
            text-align: center;
            margin-top: 5px;
            position: absolute;
            left: 50%;
            margin-left: -167.5px;
        }
        .color{
            color: #FC6C21;
        }
        .bold{
            color: #000000;
            font-weight: bold;
        }
    }
    .buy-box{
        width: 310px;
        height: 520px;
        .content{
            width: 100%;
            height: 720px;
            background: url(../../assets/image/detail/bg-buy.png) center/100%,100% no-repeat;
            position: relative;
            .box-ewm{
                width: 160px;
                height: 100px;
                position: absolute;
                top: 440px;
                left: 75px;
                z-index:15px;
                .ewmimg{
                    width: 100px;
                    height: 100px;
                    margin-left: 30px;
                }
                .btn-ewm{
                    font-size: 13px;
                    color: #FC6C21;
                    font-weight: bold;
                    line-height: 18px;
                    text-align: center;
                }
            }
            .box-htjy{
                width: 245px;
                height: 68px;
                position: absolute;
                top: 629px;
                left: 27px;
            }
        }
    }
    .hj-box{
        margin: 10px 0;
        background: #ffffff;
        .title{
            text-align: start;
            padding: 12px 15px;
        }
        .hj-list{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 15px;
            max-height: 124px;
            overflow: hidden;
            .hj-item{
                width: 156px;
                height: 50px;
                box-sizing: border-box;
                background: #F3F5F6;
                border-radius: 4px;
                font-size: 14px;
                color: #8B8B8B;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                padding: 0 12px;
                line-height: 20px;
            }
            .hj-item-01{
                border: 1px solid #FC6C21;
                color: #FC6C21;
                background: #FFF3ED;
            }
        }
        .hj-list-01{
            max-height: max-content;
            overflow: auto;
        }
        .hj-more{
            font-size: 11px;
            color: #A8A8A8;
            text-align: center;
            padding-bottom: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-more{
                display: block;
                width: 10px;
                height: 10px;
                margin-left: 2px;
                background: url(../../assets/icons/icon-hj-more.png) center/100% 100% no-repeat;
            }
        }
    }
}